













import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";
import PrintTechnologiesMixin from "@/mixins/http/PrintTechnologiesMixin";
import { RequestData } from "@/store/types/RequestStore";
import { namespace } from "vuex-class";
import { PrintTechnology } from "../../models";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { RegisterHttp } from "@/utils/Decorators";

const tag = 'get_print_technologies_tag';

@Component({ components: { VSelect } })
export default class PrintTechnologySelect extends Mixins(PrintTechnologiesMixin) {
  @Prop()
  selected!: string | null;

  @Prop()
  queryKey?: string;

  @Prop()
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  @Prop({ default: false })
  actsAsFilter: boolean;

  @Prop()
  filter: any;

  options: VSelectOption[] = [];

  private value: string | null = this.selected || null;

  @RegisterHttp(tag) readonly request!: RequestData;

  mounted() {
    this.loadItems();
  }

  get payload() {
    let data: any = {
      page: {
        size: 100
      }
    };

    if (this.filter) {
      data.filter = this.filter;
    }

    return data;
  }

  get filterDefault() {
    return { id: "all", text: "All" };
  }

  get loading() {
    return this.request?.loading;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected)?.id;
    }
  }

  async loadItems() {
    if (this.actsAsFilter) {
      this.options.unshift(this.filterDefault);
    }

    const [data, errors] = await easync(this.getPrintTechnologies(this.payload, tag));
    if (errors) {
      showErrorToast();
      return;
    }

    this.options = data.map((o:PrintTechnology)  => {
      return { id: o.id, text: `${o.name}` };
    });
    this.onItemsReady();
  }
}


import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_PRINT_TECHNOLOGY_URL = BASE_API_URL+"print_technologies"


@Component
export default class PrintTechnologiesMixin extends Vue {

  getPrintTechnologies(data: any, tag: string) {
    return this.$httpGetQuery( BASE_PRINT_TECHNOLOGY_URL, tag, data);
  }

  destroyPrintTechnology(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRINT_TECHNOLOGY_URL+"/"+id, tag);
  }

  createPrintTechnology(data: any, tag: string) {
    return this.$httpPost(BASE_PRINT_TECHNOLOGY_URL, tag, data);
  }

  updatePrintTechnology(data: any, tag: string) {
     return this.$httpPut(BASE_PRINT_TECHNOLOGY_URL+"/"+data.id, tag, data);
  }
}
